<template>
    <div class="VoicePhoneNumbersNew">
        <ValidationObserver ref="observer"
                            v-slot="{ passes }">
            <form ref="form"
                  @submit.prevent="passes(onFormSubmit)">
              <div v-if="general">
                <div class="new-phone-number">
                  <base-phone-number ref="phoneNumber"
                                     placeholder="Phone number"
                                     rules="required|mobilePhone"
                                     v-model="phoneNumber"
                                     @countryChanged="onCountryChanged"/>

                  <base-button type="submit"
                               class="add"
                               color="primary"
                               :loading="submitting"
                               >Add Phone Number</base-button>
                </div>
              </div>
              <div v-else>
                <div class="new-phone-number">
                    <base-phone-number ref="phoneNumber"
                                       placeholder="Phone number"
                                       rules="required|mobilePhone"
                                       v-model="phoneNumber"
                                        @countryChanged="onCountryChanged"/>

                    <base-button type="submit"
                                 class="add"
                                 color="primary"
                                 :loading="submitting"
                                 :disabled="alreadyAdded">Add Phone Number</base-button>
                </div>

                <p class="mt-2" v-if="alreadyAdded">This phone number is already added</p>
              </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
import voiceApi from '@/api/monitorNotifications/voiceApi.js'

export default {
  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      phoneNumber: '',
      submitting: false
    }
  },

  mounted () {
    this.focusPhoneNumber()
  },

  methods: {
    async onFormSubmit () {
      if (this.general) {
        try {
          this.submitting = true
          await voiceApi.generalAddPhoneNumber(this.phoneNumber)
          this.$emit('add')
          this.submitting = false

          this.$refs.observer.reset()

          this.phoneNumber = ''
        } catch (e) {
          this.$emit('error', { 'errorMonitor': e.response.data.errorMonitor ?? 'Something went wrong', 'hyperlink': e.response.data.hyperlink ?? false })
          this.submitting = false
        }
      } else {
        if (this.alreadyAdded) {
          return false
        }

        this.submitting = true

        await voiceApi.addPhoneNumber(this.monitor.id, this.phoneNumber)
        this.$emit('add')

        this.submitting = false

        this.$refs.observer.reset()

        this.phoneNumber = ''
        this.flushFocus()
      }
    },

    focusPhoneNumber () {
      this.$refs.phoneNumber.focus()
    },

    flushFocus () {
      this.$refs.phoneNumber.focus()
    },

    onCountryChanged (country) {
      if (!this.phoneNumber) {
        this.phoneNumber = '+' + country.dialCode
      }
    }
  },

  computed: {
    phoneNumbers () {
      if (!this.general) {
        return this.monitor.notification_channels
          .filter(channel => channel.via === 'twilio_voice')
          .map(channel => channel.phone_number)
      } else {
        return null
      }
    },

    alreadyAdded () {
      if (!this.general) {
        return this.phoneNumbers.some(number => number === this.phoneNumber)
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .VoicePhoneNumbersNew {
        .new-phone-number {
            display: flex;

            ::v-deep .base-phone-number {
                width: 300px;
                margin: 0;
            }

            .add {
                width: 200px;
                margin: 2px 0 0 10px;
            }
        }
    }
</style>
